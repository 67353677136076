import React from 'react';
import GoogleMapReact from 'google-map-react';
import pinStyle from '../../assets/icons/pin';
import pin from '../../assets/icons/pin.png';
import { Button, Carousel, Modal } from 'react-bootstrap';

const LocationModal = (props) => {

	const KEY = process?.env.REACT_APP_MAP_API_KEY;

  const handleClose = () => props.close();
  const AnyReactComponent = ({ text }) => (
    <div className="pin">
      <img src={pin} />
      <strong>{text}</strong>
    </div>
  );

  return (
    <Modal
      show={props?.showLocationModal !== null}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="bg-dark text-white">
        <div className="d-flex flex-column m-0 p-0">
          <Modal.Title>{props?.showLocationModal?.name}</Modal.Title>
          <div>
            <p className="mb-0">
              {props?.showLocationModal?.address }
            </p>
            <p className="mb-0">{props?.showLocationModal?.phone}</p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div

        >
          <GoogleMapReact
            style={{
              width: '800px',
              height: '500px',
              margin: 0,
              padding: 0,
            }}
            bootstrapURLKeys={{
              key: KEY
            }}
            defaultCenter={props?.showLocationModal?.coordinates}
            defaultZoom={16}
          >
            <AnyReactComponent
              lat={props?.showLocationModal?.coordinates?.lat}
              lng={props?.showLocationModal?.coordinates?.lng}
              text={'The Bunker'}
            />
          </GoogleMapReact>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LocationModal;
