import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import firebase from "firebase/app";
import "firebase/storage";
import { useAlert } from "react-alert";

const MessagingPage = (props) => {


  const ad_page_config = props.firebase.ad_page_config();

  const alert = useAlert();


  // FIRESTORE CALL 
  function saveTickerMessage() {

    ad_page_config.set({ tickerMessage: tickerMessage }).then(() => {
      console.log('successfully updated ticker message to firestore')
      alert.success("Ticker message successfully updated!");

    }).catch(err => {
      alert.error("Ticker message was not updated.");

      console.log(err)
    })
  }

  return (
    <div className="p-3 w-100 h-100 bg-light">
      <div className="d-flex w-100 justify-content-between">
        <h1 className="m-0">Messaging Services</h1>
      </div>
      <hr />
     
    </div >
  )
};

const condition = authUser =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition)
)(MessagingPage);
