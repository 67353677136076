import { useEffect, useState } from 'react';
import { withFirebase } from '../../components/Firebase';
import './index.scss'; // Adjust the import to your file structure
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import { format, parse } from 'date-fns';

function CalendarAdmin(props) {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const querySnapshotCalendarEvents = await props.firebase
        .calendarEventsAll()
        .get();
      let calendarEventsData = querySnapshotCalendarEvents.docs.map(
        (doc) => ({
          id: doc.id,
          ...doc.data(),
          showEvents: doc.data()?.show,
        })
      );

      setLocations(calendarEventsData);
    };

    fetchLocations();
  }, [props.firebase]);

  const handleEditEvent = (index, field, value) => {
    const updatedEvents = [...calendarEvents];
    if (field === 'date') {
      updatedEvents[index][field] = value;
      updatedEvents[index]['timestamp'] = parse(value, 'yyyy-MM-dd', new Date());
    } else {
      updatedEvents[index][field] = value;
    }
    setCalendarEvents(updatedEvents);
  };

  const handleToggleShowOnFeed = (index) => {
    const updatedEvents = [...calendarEvents];
    updatedEvents[index].showOnFeed = !updatedEvents[index].showOnFeed;
    setCalendarEvents(updatedEvents);
  };

  const handleLocationChange = async (event) => {
    if (!event.target.value) return;

    const locationId = event.target.value;
    setSelectedLocation(locationId);

    const docRef = await props.firebase.calendarEvents(locationId).get();
    if (docRef.exists) {
      const locationData = docRef.data();

      let calendarEvents = locationData?.calendarEvents?.map((e) => ({
        ...e,
        showOnFeed: e.showOnFeed || false,
      })).sort((a, b) => a.timestamp - b.timestamp);

      setCalendarEvents(calendarEvents || []);
    } else {
      setCalendarEvents([]);
    }
  };

  const handleAddEvent = () => {
    setCalendarEvents([
      ...calendarEvents,
      { title: '', date: format(new Date(), "yyyy-MM-dd"), timestamp: new Date(), description: '', showOnFeed: false },
    ]);
  };

  const handleRemoveEvent = (index) => {
    const updatedEvents = [...calendarEvents];
    updatedEvents.splice(index, 1);
    setCalendarEvents(updatedEvents);
  };

  const handleSave = async () => {
    try {
      const selectedLocationObj = locations.find(loc => loc.id === selectedLocation);
      const updatedEventsWithLocation = calendarEvents.map(event => ({
        ...event,
        locationName: selectedLocationObj?.locationName || 'Unknown Location',
      }));

      await props.firebase
        .calendarEvents(selectedLocation)
        .set({ calendarEvents: updatedEventsWithLocation }, { merge: true });
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Error saving changes. Please try again.');
    }
  };

  return (
    <div className="calendar-admin">
      <h1 className="header">Calendar Admin Page</h1>
      <label className="location-selector-label">
        Select Location:
        <select
          className="location-selector"
          onChange={handleLocationChange}
        >
          <option value="">Select a location</option>
          {locations.map((location) => (
            <option key={location.id} value={location.id}>
              {location.locationName}
            </option>
          ))}
        </select>
      </label>

      {selectedLocation && (
        <div className="events-section">
          <h2 className="events-header">Events:</h2>
          {calendarEvents.length === 0 ? (
            <div className="no-events-message">
              No events created yet. Click 'Add New Event' to get started.
            </div>
          ) : (
            <ul className="events-list">
              {calendarEvents.map((event, index) => (
                <li key={index} className="event-item">
                  <div className="event-field">
                    <label>Title:</label>
                    <input
                      type="text"
                      className="event-input"
                      value={event.title}
                      onChange={(e) =>
                        handleEditEvent(index, 'title', e.target.value)
                      }
                      placeholder="Title"
                    />
                  </div>

                  <div className="event-field">
                    <label>Date:</label>
                    <input
                      type="date"
                      className="event-input"
                      value={event.date}
                      onChange={(e) => {
                        handleEditEvent(index, 'date', e.target.value)
                      }}
                    />
                  </div>

                  <div className="event-field">
                    <label>Description:</label>
                    <input
                      type="text"
                      className="event-input"
                      value={event.description}
                      onChange={(e) =>
                        handleEditEvent(index, 'description', e.target.value)
                      }
                      placeholder="Description"
                    />
                  </div>

                  <div className="event-field">
                    <label>Show On Feed:</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={event.showOnFeed}
                        onChange={() => handleToggleShowOnFeed(index)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>

                  <button
                    className="remove-button"
                    onClick={() => handleRemoveEvent(index)}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          )}
          <hr />
          <div className="actions">
            <button className="add-button" onClick={handleAddEvent}>
              Add New Event
            </button>
            <button className="save-button" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(
  withFirebase,
  withAuthorization(condition),
)(CalendarAdmin);
