import React, { useState } from 'react';
import './index.scss';
import { Button } from 'react-bootstrap';
import locationSVG from '../../assets/icons/bunker-location_icon.svg';
import menuSVG from '../../assets/icons/bunker-menu_icon.svg';
import promotionSVG from '../../assets/icons/bunker-promotion_icon.svg';
import { Footer } from '../Landing';
import locations from './config';
import LocationModal from './LocationModal.js';
import PromotionModal from './PromotionModal.js';
import MenuModal from './MenuModal.js';
import { useHistory, Link } from 'react-router-dom';
import { AiOutlineClockCircle, AiOutlinePhone } from 'react-icons/ai';
import { BiMap } from 'react-icons/bi';
import BookNowButton from '../../components/BookNowButton/BookNowButton.js';
import MealeoButton from '../../components/MealeoButton';
import { FiAlertTriangle } from 'react-icons/fi';
import Calendar from '../../components/CalendarComponent';
import { withFirebase } from '../../components/Firebase';
import CalendarModal from './CalendarModal';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { MdTakeoutDining } from 'react-icons/md';
import BookEventsButton from '../../components/BookEventsButton/BookEventsButton';

const LocationPage = (props) => {
  const [showLocationModal, setLocationModal] = useState(null);
  const [showMenuModal, setMenuModal] = useState(null);
  const [showPromotionModal, setPromotionModal] = useState(null);
  const [showCalendarModal, setCalendarModal] = useState(null);

  const history = useHistory();

  const Image = React.memo(function Image({ src, width, height }) {
    return <img src={src} width={width} height={height} />;
  });

  const Title = () => (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">OUR LOCATIONS</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/bunker-imgs-3.jpg"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const Images = (props) => (
    <div className="gallery">
      <div className="item-0">
        <Image
          src={props?.location?.images[0]}
          width={'100%'}
          height={'100%'}
        />
        <div className="bottom-left">
          {props.location.name?.toUpperCase()}
        </div>
      </div>

      <div className="item-1">
        <Image
          src={props?.location?.images[1]}
          width={'100%'}
          height={'100%'}
        />
      </div>

      <div className="item-2">
        <Image
          src={props?.location?.images[2]}
          width={'100%'}
          height={'100%'}
        />
      </div>

      <div className="item-3">
        <Image
          src={props?.location?.images[3]}
          width={'100%'}
          height={'100%'}
        />
      </div>

      <div className="item-4">
        <Image
          src={props?.location?.images[4]}
          width={'100%'}
          height={'100%'}
        />
      </div>
    </div>
  );

  const PeakRates = (props) => (
    <div className="rates item-1">
      <strong className="text-primary">PEAK RATES</strong>
      <p>{props.location.peakRates?.range}</p>

      {props.location.peakRates?.bays.map((bay, i) => {
        return (
          <p key={i} className="mb-1">
            <strong>{bay.name}: </strong> {bay.price}
          </p>
        );
      })}
    </div>
  );

  const NonPeakRates = (props) => (
    <div className="rates item-2">
      <strong className="text-primary">NON-PEAK RATES</strong>
      <p>{props.location.nonPeakRates?.range}</p>

      {props.location.nonPeakRates?.bays.map((bay, i) => {
        return (
          <p key={i} className="mb-1">
            <strong>{bay.name}: </strong> {bay.price}
          </p>
        );
      })}
    </div>
  );

  const About = (props) => (
    <div className="rates item-3">
      <h5 className="text-primary mb-2">TRAIN LIKE A PGA PRO</h5>
      <p>
        This new studio will provide golf enthusiasts, amateurs, and
        professionals alike an opportunity to improve their golf
        skills year round using TRACKMAN, the world's premier launch
        monitor and golf simulator.
      </p>
      <p>
        This unique partnership with{' '}
        <span className="text-primary">VENT Fitness</span> offers
        custom fitness training programs designed to increase strength
        and flexibility to help golfers take their game to the next
        level.
      </p>
      <p>
        Available by the half hour. Golf professionals will be
        available by appointment, and can provide assistance for all
        skill levels, from youth to adult.
      </p>
    </div>
  );

  const MoreInfo = (props) => (
    <div className="rates item-0 d-flex flex-column">
      <div className="mb-2">
        {props.location.notice ? (
          <div className="rounded p-2 m-1 bg-warning font-weight-bold flex align-items-center">
            <FiAlertTriangle className="mx-2 mb-1" />
            {props.location.notice}
          </div>
        ) : null}

				<div className="d-flex">

        <BookNowButton url={props.location.url} />
				<BookEventsButton />
				</div>
        <div className="text-dark mb-3 d-flex flex-column">
          <span>
            <BiMap size={30} className="pr-2 text-primary" />
            {props.location.address}
          </span>
          <span>
            <AiOutlinePhone size={30} className="pr-2 text-primary" />
            {props.location.phone}
          </span>
          {props.location?.hoursFull && (
            <span>
              <AiOutlineClockCircle
                size={30}
                className="pr-2 text-primary"
              />
              {props.location.hoursFull}
            </span>
          )}
        </div>
      </div>
      <div className="d-flex flex-wrap w-100 justify-content-around text-primary">
        <div
          className="icon-container"
          onClick={() => {
            setLocationModal(props.location);
          }}
        >
          <img
            src={locationSVG}
            className="mb-2"
            style={{ height: '30px', width: '30px' }}
          ></img>
          <p style={{ fontSize: 10 }}>LOCATION</p>
        </div>
        <div
          className="icon-container"
          onClick={() => {
            console.log(props.location);
            setCalendarModal(props.location);
          }}
        >
          <FaRegCalendarAlt
            className="text-primary mb-2"
            style={{
              height: '30px',
              width: '30px',
            }}
          />
          <p style={{ fontSize: 10 }}>CALENDAR</p>
        </div>

        {props.location.promotions.length ? (
          <div
            className="icon-container"
            onClick={() => {
              if (props.location.promotions.length)
                setPromotionModal(props.location);
            }}
          >
            <img
              src={promotionSVG}
              className="mb-2"
              style={{ height: '30px', width: '30px' }}
            ></img>
            <p style={{ fontSize: 10 }}>PROMOS</p>
          </div>
        ) : null}
        {props.location.menus?.length ? (
          <div
            className="icon-container"
            onClick={() => {
              setMenuModal(props.location);
            }}
          >
            <img
              className="mb-2"
              style={{ height: '30px', width: '30px' }}
              src={menuSVG}
            ></img>
            <p style={{ fontSize: 10 }}>MENU</p>
          </div>
        ) : null}
        {props.location.mealeo ? (
          <a
            href={props.location.mealeo}
            target="_blank"
            className="icon-container"
          >
            <MdTakeoutDining
              style={{
                height: '30px',
                width: '30px',
              }}
              className="mb-2"
            />
            <p style={{ fontSize: 10 }}>TOGO</p>
          </a>
        ) : null}
      </div>
    </div>
  );

  const Details = (props) => {
    return (
      <div className="location-details-container">
        <MoreInfo {...props} />

        {props.location.nonPeakRates ? (
          <NonPeakRates {...props} />
        ) : null}
        {props.location.peakRates ? <PeakRates {...props} /> : null}
        {props.location.about ? <About {...props} /> : null}
      </div>
    );
  };

  const Amenities = (props) => {
    console.log(location.amenities);
    return (
      <div className="amenities-container">
        <div className="d-flex flex-column w-100 p-4">
          <h4 className="font-weight-bold pb-4 mb-4">AMENITIES:</h4>
          <div className="amenities-container">
            {props?.location.amenities?.map((a, i) => (
              <div className="amenity-item" key={i}>
                <img
                  style={{
                    height: a.height || '60px',
                    width: a.width || '60px',
                    objectFit: 'contain',
                    marginBottom: '10px',
                  }}
                  src={a.icon}
                  alt={a.title}
                />
                <p>{a.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const Location = (location) => (
    <div className="locations-container">
      <Images location={location} />
      <Details location={location} />
      <Amenities location={location} />
      {/* <Calendar location={location} firebase={props.firebase} /> */}
    </div>
  );

  const Locations = () =>
    locations.map((loc, id) => {
      return (
        <div key={id} style={{ fontSize: '13px' }}>
          <Location {...loc} />
          <hr />
        </div>
      );
    });

  const toggleCalendarModal = (location) => {
    setCalendarModal((prevState) => (prevState ? null : location));
  };

  const ComingSoonLocations = () => {
    return (
      <div className="m-3">
        {/* <h3 className="text-primary font-weight-bold m-0">
          <span className="grey">THE BUNKER SARATOGA //</span>{' '}
          COMING SOON
        </h3> */}
      </div>
    );
  };

  const Notice = () => (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        history.push({ pathname: '/contact-us' });
      }}
    >
      <h3 className="notice text-uppercase bg-light text-center font-weight-bold text-primary m-0 bg-grey p-2">
        We’re growing!
        <span className="grey mx-4">
          Have a spot for us in your area?
        </span>
        Let us know!
      </h3>
    </div>
  );

  return (
    <div className="location-container">
      <Notice />
      <Title />
      <Locations />
      <ComingSoonLocations />
      <LocationModal
        showLocationModal={showLocationModal}
        close={() => {
          setLocationModal(null);
        }}
      />
      <MenuModal
        showMenuModal={showMenuModal}
        close={() => {
          setMenuModal(null);
        }}
      />
      <PromotionModal
        showPromotionModal={showPromotionModal}
        close={() => {
          setPromotionModal(null);
        }}
      />
      <CalendarModal
        firebase={props.firebase}
        showCalendarModal={showCalendarModal}
        close={() => setCalendarModal(null)}
      />
      <Footer />
    </div>
  );
};
export default withFirebase(LocationPage);
