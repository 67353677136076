import React from 'react';
import { Link } from 'react-router-dom';

function MealeoButton(props) {
  let style = 'text-white p-3 rounded bg-primary small';

  if (props?.dark) style = style.replace('bg-primary', 'bg-black');

  if (props?.lg) style = style.replace('small', '');

  return (
    // <div>
    <Link
      className={style}
      style={props?.style}
      to={{
        pathname: props.url,
      }}
      target="_blank"
    >
      {props.title}
    </Link>
    // </div>
  );
}

export default MealeoButton;
