import { useState, useEffect } from 'react';

function useAsyncDebounce(callback, delay) {
  const [debouncing, setDebouncing] = useState(false);

  useEffect(() => {
    if (debouncing) {
      const timeoutId = setTimeout(async () => {
        setDebouncing(false);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [debouncing, delay]);

  const debouncedCallback = async (v) => {
    if (!debouncing) {
      setDebouncing(true);
      await callback(v);
    }
  };

  return debouncedCallback;
}

export default useAsyncDebounce;
