import React from 'react';
import "./index.scss";
import { Button } from 'react-bootstrap'
import { useLocation } from "react-router-dom";
import useWindowDimensions from "../../components/useWindowDimensionsHook.js";

const CourseTeesheet = () => {
  const location = useLocation();
  const window = useWindowDimensions();

  const breakpoint = (window.width > 992) ? 300 : 0;



  return (
    <div className="course-teesheet-container" style={{ width: window.width - breakpoint }}>
      <iframe src={location?.state?.url} />
    </div>
  );

}

export default CourseTeesheet;
