// hooks/useNoticeInfo.js
import { useState, useEffect } from 'react';

const useNoticeInfo = (firebase) => {
  const [noticeInfo, setNoticeInfo] = useState({});

  function setShowInfoModal(v) {
    setNoticeInfo({
      ...noticeInfo,
      infoModal: { ...noticeInfo.infoModal, showInfoModal: v },
    });
  }

  useEffect(() => {
    const unsubscribe = firebase.noticeInfoListener((data) => {
      setNoticeInfo(data);
    });

    return () => {
      unsubscribe();
    };
  }, [firebase]);

  return {
    noticeInfo,
    setShowInfoModal,
  };
};

export default useNoticeInfo;
