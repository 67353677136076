import React from 'react';
import './index.scss';
import { Footer } from '../Landing';
import { useHistory } from 'react-router-dom';

const Title = () => {
  return (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">FAQS</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/clifton-park/three.jpg"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};

const cursorPointer = { cursor: 'pointer' };

const Main = () => {
  const history = useHistory();
  return (
    <div className="faq-question-container flex-fill h-100">
      <strong className="faq-question">
        How do you book a tee time?
      </strong>
      <p>
        You can book online by choosing a location{' '}
        <span
          className="font-weight-bold text-primary"
          style={cursorPointer}
          onClick={() => {
            history.push('/location');
          }}
        >
          HERE
        </span>
        !
      </p>

      <strong className="faq-question">
        Is the rate per bay or per person?
      </strong>
      <p>
        The hourly rate is per Bay! You can have up to 8 people in a
        bay at one time and can split the cost between the number of
        golfers playing. <br /> <strong>For example</strong>: If 4
        people are playing in one bay for one hour, the cost would be
        $15 per person per hour.
      </p>

      <strong className="faq-question">How long does it take?</strong>
      <p>
        We recommend 30 minutes per person for 9 holes, One hour per
        person for 18. So if you have a foursome you could play 9
        holes in two hours.
      </p>

      <strong className="faq-question">
        What do I need to wear?
      </strong>
      <p>
        We are friendly to all types of attire! Golf attire and
        athletic wear are both welcomed including jeans.
      </p>

      <strong className="faq-question">Can I wear golf shoes?</strong>
      <p>Golf shoes are acceptable but not necessary.</p>

      <strong className="faq-question">
        Will I need to bring clubs?
      </strong>
      <p>
        You are welcome to bring your own clubs. Or we have plenty of
        clubs at our facility for you to use! Please note you must
        bring your own clubs to Latham GPS.
      </p>

      <strong className="faq-question">
        Do I need to golf to come in?
      </strong>
      <p>
        NO! Our bar is open to the public and welcomes guests whether
        you're golfing or not.
      </p>

      <strong className="faq-question">
        Where do I enter at the Guilderland location?
      </strong>
      <p>
        The entrance to The Bunker is in the back of the building!
        Park in the lot behind the building and enter from that side.
      </p>
    </div>
  );
};

const FAQPage = () => (
  <div className="faq-container">
    <Title />
    <Main />
    <Footer />
  </div>
);

export default FAQPage;
