import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import CalendarModal from '../../pages/Location/CalendarModal';
import locations from '../../pages/Location/config.js';
import "./index.scss"

const LocationSelector = (props) => {
  const [showCalendarModal, setShowCalendarModal] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setDropdownOpen(isOpen);  // Toggle the dropdown based on the isOpen argument
  };

  const handleLocationSelect = (location) => {
    if (location) setShowCalendarModal(location);
    setDropdownOpen(false);  // Close the dropdown when a location is selected
  };

  const handleModalClose = () => {
    setShowCalendarModal(null);
  };

  return (
    
    <div className={`${props?.style} d-flex m-1 mb-2`}>
      <Dropdown className="w-100" show={isDropdownOpen === true} onToggle={handleToggle} autoClose={true}>
        <Dropdown.Toggle variant="primary" id="location-dropdown-basic" size="sm">
          EVENTS CALENDAR
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {locations.map((l, i) => (
            <Dropdown.Item eventKey={i} key={i} onClick={() => handleLocationSelect(l)}>
              {l.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <CalendarModal
        showCalendarModal={showCalendarModal}
        close={handleModalClose}
        firebase={props.firebase}
      />

    </div>
  );
};

export default LocationSelector;
