import React, { useEffect } from 'react';

const RedirectPage = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <div>Redirecting...</div>;
};

export default RedirectPage;
