import React from 'react';
import './index.scss';
import { Button } from 'react-bootstrap';
import { Footer } from '../Landing';

const Title = () => (
  <div className="title-container">
    <div className="overlay">
      <h1 className="title text-white m-0">THE BUNKER IS BORN</h1>
    </div>
    <img
      src="https://storage.googleapis.com/thebunker-assets/thebunker/CP_FLOORPLAN.png"
      width={'100%'}
      height={'100%'}
    />
  </div>
);

const Main = () => (
  <div className="main px-3 flex-fill h-100">
    <div className="mt-4 pt-4 ml-4">
      <h1 className="text-primary mb-4 font-weight-bold">
        "Let's measure the ceiling"
      </h1>
      <p>
        In the Spring of 2019, lifelong friends Troy Miller and Burl
        McCutcheon were driving home from their first time playing
        indoor golf when a fevered conversation erupted about what
        would make the best possible indoor golf experience. They
        agreed that it would have to be a luxury environment, the best
        technology possible was a necessity, and the service had to be
        nothing less than exceptional. Then Burl asked, "Why not build
        one ourselves in the basement of your commercial building?"
      </p>
      <p>
        A frenzied google search revealed that they would need at
        least 9'6" of ceiling height for a simulator. "Let's measure
        the ceiling!" The excitement was electric as they pulled into
        the parking lot of 2390 Western Ave., and tape measure in
        hand, Troy scrambled through the front door and measured...
        10'! Just a few months later in October of 2019, after plenty
        of brainstorming, market research, hard work, and sweat, The
        Bunker opened its doors to the public.
      </p>
      <p>
        The response from golfers and guests was overwhelmingly
        positive, and it was clear to Troy and Burl that they had
        created the foundation for a brand new sports- entertainment
        business. The principles they conceived on their drive to
        measure the ceilings would set the foundation for The Bunker
        and will carry forward into future expansion; a luxury
        environment, the best technology available (TRACKMAN), and
        exceptional customer service.
      </p>
    </div>
    <div className="m-4 p-4 d-flex justify-content-center">
      <img
        className=""
        src="https://storage.googleapis.com/thebunker-assets/thebunker/TB_BLACK_LOGO.jpg"
        width={'100%'}
        height={'100%'}
        style={{ maxHeight: '600px', maxWidth: '400px' }}
      />
    </div>
  </div>
);

const AboutPage = () => (
  <div className="about-container">
    <Title />
    <Main />
    <Footer />
  </div>
);

export default AboutPage;
