import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';

const EventModal = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const handleClose = () => props.close();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(amt) {
    let res = pageNumber + amt;
    if (res < 1) res = numPages;
    if (res > numPages) res = 1;
    setPageNumber(res);
  }

  return (
    <Modal
      show={props?.showEventModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="w-100"
    >
      <Modal.Header className="bg-dark text-white">
        <Modal.Title>{props.location?.name} Events</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark text-white">
        <div>
          <Document
            style={{ width: '100%', height: '100%' }}
            file={props.location?.pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="d-flex justify-content-between mt-3">
            <Button variant="light" onClick={() => changePage(-1)}>
              Prev
            </Button>

            <p>
              Page {pageNumber} of {numPages}
            </p>
            <Button variant="light" onClick={() => changePage(1)}>
              Next
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EventModal;
