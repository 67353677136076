import React from 'react';
import TheBunkerHomePageVideo from '../../assets/thebunker_home_video.mp4';
import "./index.scss";
import { Button, Navbar, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const GiftCardsPage = (props) => {



  return (
    <>
      <Modal size="lg" show={props.show} onHide={props.hide}>
        <Modal.Header className="text-primary">
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="grey mb-0">Gift cards are redeemable at all <span className="font-weight-bold text-primary">The Bunker</span> locations!</h5>
          {/* <h5 className="grey mb-0">Online Gift Cards are currently down! Please call 518-867-3008 to purchase a Gift Card!</h5> */}
          
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <a className="bg-light rounded p-3 text-dark font-weight-bold" target="_blank" href="https://www.clover.com/online-ordering/the-bunker-guilderland/giftcard" onClick={props.hide}>Go To Gift Cards</a>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default GiftCardsPage;
