import React from 'react';
import TheBunkerHomePageVideo from '../../assets/thebunker_home_video.mp4';
import "./index.scss";
import { Button } from 'react-bootstrap'

const ShopPage = () => (
  <div className="shop-container">
    Shop Gear Works!
  </div>
);

export default ShopPage;
