import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Calendar from '../../components/CalendarComponent';  // Adjust the import path accordingly

const CalendarModal = (props) => {
  const handleClose = () => props.close();

  return (
		
    <Modal
		id="calendar"
      show={props.showCalendarModal !== null}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>
          {props.showCalendarModal?.name} Calendar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar
          location={props.showCalendarModal}
          firebase={props.firebase}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
		
  );
};

export default CalendarModal;
