import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function BookNowButton(props) {
  const history = useHistory();

  return (
    <div className={`${props?.style} d-flex m-1 mb-2`}>
      <Button
        variant="primary"
        className="w-100"
        size="sm"
        onClick={() => {
          history.push({
            pathname: '/teesheet',
            state: {
              url: props.url,
            },
          });
        }}
      >
        BOOK NOW
      </Button>
    </div>
  );
}

export default BookNowButton;
