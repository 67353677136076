import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
import * as ROLES from '../../constants/roles';

import firebase from 'firebase/app';
import 'firebase/storage';
import './index.scss';
import useWindowDimensions from '../../components/useWindowDimensionsHook';

const fetchGraphicImages = async () => {
  const result = await firebase
    .storage()
    .ref(`images/mohawkharbor`)
    .listAll();
  const urlPromises = result.items.map((imageRef) =>
    imageRef.getDownloadURL(),
  );
  return Promise.all(urlPromises);
};

const AdMHTest = (props) => {
  const { height, width } = useWindowDimensions();
  const [graphics, setGraphics] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);

  const loadImages = async () => {
    try {
      const graphics = await fetchGraphicImages();
      setGraphics(graphics);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadImages();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % graphics.length);
    }, 10000); // Change images every 10 seconds
    return () => clearInterval(timer); // Clean up function
  }, [currentIndex, graphics]);

  useEffect(() => {
    const unsubscribe = props.firebase.db
      .collection('locations')
      .doc('mohawkharbor')
      .onSnapshot((doc) => {
        if (doc.exists) {
          setLastUpdated(doc.data().last_updated);
          // Reload images
          loadImages();
        }
      });

    return () => unsubscribe(); // Clean up on unmount
  }, [props.firebase.db]);

  return (
    <div
      className="rotated-carousel"
      style={{ width: height, height: width }}
    >
      {graphics.map((graphic, index) => (
        <div
          className={`carousel__item ${
            index === currentIndex ? 'carousel__item--visible' : ''
          }`}
          key={index}
        >
          <img
            className="slide-image"
            src={graphic}
            alt={`Slide ${index}`}
          />
        </div>
      ))}
    </div>
  );
};

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(AdMHTest);
