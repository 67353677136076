// src/pages/MembershipPage/index.jsx

import React, { useState } from 'react';
import { Footer } from '../Landing';
import { withFirebase } from '../../components/Firebase';
import '../../utils/shared.scss';
import './index.scss';
// import LessonInterestForm from '../../components/LessonInterestForm';
import { Spinner, Modal, Button } from 'react-bootstrap';

const HEADER = 'MEMBERSHIP';
const TITLE = 'THE BUNKER ELITE MEMBERSHIP';
const SUBTITLE =
  "Contact us using the form below, and we'll get back to you shortly!";

const PAYMENT_OPTIONS = [
  {
    title: '$1500 for the season',
    value: 'one time payment (11/1/24-4/30/25) Over $6000 VALUE',
  },
  { title: '$275 per month', value: 'two month minimum' },
  {
    title: '$350 for one month',
    value: 'one time payment ($1000 VALUE)',
  },
];

const PERKS = [
  'Gives you 60 minutes of simulator time in standard bays ONLY per day during non-peak hours. 10% off simulator time used beyond 60 minutes. Non-peak hours are Monday-Thursday from 9am-3pm ($1000 VALUE) excludes Holidays',
  'Members playing together can add their 60 minute time allocations. (e.g. 3 members can occupy one sim for 3 hours without incurring additional charges)',
  '10% off simulator time during peak times including guests (any bay)',
  '10% off food and beverage anytime',
  '10% off lessons/club fittings',
  'Club storage if available',
  '$100 OFF one party per year ($1000 min. spend)',
  'Can be used at any location',
  'Black card issued',
  '20% off apparel',
];

const DETAILS = [
  'You CAN use your hour towards Non-peak golf league fees',
  'Cannot be used toward any peak leagye fees or tournaments and discounts do not apply to these fees as well',
  'Members must be present to utilize benefits',
  'Guests will be charged $15 per person during the first hour, the total cost paid by guest(s) will not exceed the listed hourly rate for the bay that will be used',
  'Membership is nontransferable and can only be used by member card holders. Violation will result in membership cancellation without a refund given',
  'Monthly membership will automatically be renewed for following month unless member gives one week notice to cancel',
];

const FAQS = [
  {
    q: 'What are your membership hours?',
    a: 'Our facility is open Monday through Thursday from 9 am-3 pm. Excludes Holidays. Please check our website for any special holiday hours.',
  },
  {
    q: 'How do I book simulator time?',
    a: 'You can book simulator time through our website or by calling any one of our locations. Advance booking is recommended, especially during peak hours.',
  },
  {
    q: 'What are peak and non-peak hours?',
    a: 'Peak Hours: Weekdays anytime after 3 pm, weekends & any major holidays. Non-Peak Hours: Monday to Friday, 9 am to 3 pm excluding holidays.',
  },
  {
    q: 'Can I bring guests?',
    a: 'Absolutely! Guests are welcome. Please note there is a fee of $15 per person for the first hour, and any extra hours can be split among members and guests with a 10 percent discount.',
  },
  {
    q: 'What is included in the membership?',
    a: 'Membership includes 60 minutes of simulator time per day during non-peak hours, discounts on food, beverages, lessons, club fittings, and merchandise, as well as club storage (if available). Discounts will be applied to the entire bill as long as the group does not exceed 8 people.',
  },
  {
    q: 'How can I cancel my monthly membership?',
    a: "To cancel your monthly membership, please provide one week's notice before your next billing date. You can do this by contacting the store that purchased your membership.",
  },
  {
    q: 'Can I use my membership benefits in private or VIP rooms?',
    a: 'You’ll receive a 10% discount on any simulator time in the VIP suite or private rooms. However, the one free hour of simulator time during non-peak hours cannot be used in the VIP suite or private rooms.',
  },
  {
    q: 'Do you serve food and beverages?',
    a: 'Yes! We offer a full menu of food and beverages. Members enjoy a 10% discount on any food and beverage purchases.',
  },
  {
    q: 'Is my membership transferable?',
    a: 'No, memberships are non-transferable and can only be used by the cardholder. Violations will result in cancellation without a refund.',
  },
  {
    q: 'What happens if I exceed my simulator time?',
    a: "If you exceed your 60 minutes of simulator time during non-peak hours, you'll receive a 10% discount on any additional time used.",
  },
  {
    q: 'Can I buy a membership as a gift?',
    a: 'Yes, our One-Month Gift Membership is a great option! It provides the same benefits as a regular membership but does not automatically renew.',
  },
  {
    q: 'What happens if multiple members play together?',
    a: 'If multiple members are playing together, they can each use their one free hour of simulator time during non-peak hours collectively. For example, if three members are playing together, they would receive a total of three free hours during non-peak.',
  },
  {
    q: 'Do I get any discounts for hosting a party as a member?',
    a: 'Yes! As a member, you’re eligible for a 10% discount on one party booking per year. This discount can be applied to the total cost of the party, including simulator time, food, and beverages. Please note that this discount can only be used once per year and cannot be combined with any other promotions or discounts.',
  },
];

const MEMBERSHIP_SIGNUP_LINK = "https://forms.gle/Ce1LLJKi8tKuoxPC9";

const MembershipPage = (props) => {
  // State for loading and submission confirmation
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmission = (status) => {
    setIsLoading(false);
    if (status === 'success') {
      setShowConfirmation(true);
    }
    // No action needed for 'error' status here; error message is handled in the form component
  };

  const SignUpNowButton = () => {
    return (
			<Button className="sign-up-btn">
			<a target="_blank" href={MEMBERSHIP_SIGNUP_LINK}>SIGN UP NOW</a>
			</Button>
    );
  };

  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/Bunker_Membership_Web_Page_Header.jpg"
        alt="Lessons Background"
        width="100%"
        height="100%"
      />
    </div>
  );

  const PaymentOptions = (
    <div className="payment-options">
      <div className="section-title pb-3">PAYMENT OPTIONS</div>
      {PAYMENT_OPTIONS.map((option, index) => {
        return (
          <div className="payment-option" key={index}>
            <p>
						<span className="font-weight-bold"> {option.title}
              </span> - {option.value}
            </p>
          </div>
        );
      })}
      <SignUpNowButton />
    </div>
  );

  const Perks = (
    <div className="payment-options">
      <div className="section-title pb-2">PLENTY OF PERKS</div>
      <ul>
        {PERKS.map((perk, index) => {
          return <li key={index} className="py-2">{perk}</li>;
        })}
      </ul>
    </div>
  );

  const Details = (
    <div className="details">
      <div className="section-title">DETAILS</div>
      
			<ul>
				{DETAILS.map((detail, index) => {
					return <li key={index} className="py-2">{detail}</li>;
				})}
        
      </ul>
    </div>
  );

  const FAQs = (
    <div className="faqs">
      <div className="section-title">FAQs</div>
      <ul>
        {FAQS.map((f, index) => {
          return (
            <li key={index}>
              <div className="py-2">
                <div className="faq-question">{f.q}</div>
                <div className="faq-answer">{f.a}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const Enrollment = (
    <div className="payment-options">
      <div className="section-title">ENROLLMENT</div>
			<p>Enrolling in The Bunker Elite Membership is a two step process.</p>
			<p className="font-weight-bold mb-0 pb-0">Step 1: </p>
			<p>Click the "SIGN UP NOW" button below and complete the Google form.</p>
			<p className="font-weight-bold mb-0 pb-0">Step 2: </p>
			<p>Once the form is submitted, use the link on the submission page to complete the payment.</p>
			<SignUpNowButton />
    </div>
  );

  const Main = () => {
    return (
      <div className="main px-3 flex-fill h-100">
        <div className="pt-4 d-flex flex-column">
          <h1 className="text-dark mb-4">{TITLE}</h1>

          {PaymentOptions}
          <hr />
          {Perks}
          <hr />
          {Details}
          <hr />

          {FAQs}
          <hr />

          {Enrollment}
          <hr />
        </div>
        {/* Pass down setIsLoading and handleSubmission to the form */}
        {/* <LessonInterestForm
					firebase={props.firebase}
					setIsLoading={setIsLoading}
					handleSubmission={handleSubmission}
					isLoading={isLoading}
				/> */}
      </div>
    );
  };

  return (
    <div className="membership-container">
      {Title}
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="success" />
          <p>Submitting your inquiry...</p>
        </div>
      )}
      <Main />
      <Footer />

      {/* Submission Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your inquiry has been sent! We'll get back to you shortly.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setShowConfirmation(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withFirebase(MembershipPage);
