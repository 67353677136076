import { useParams } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';
import { useEffect, useState } from 'react';
import './index.scss';

function BeerPage(props) {
  const { id } = useParams();
  const [data, setData] = useState({
    beerData: null,
    cannedBeerData: null,
    specialsData: null,
    wineData: null,
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [beerDoc, cannedBeerDoc, specialsDoc, wineDoc] = await Promise.all([
          props.firebase.beer(id).get(),
          props.firebase.cannedBeer(id).get(),
          props.firebase.special(id).get(),
          props.firebase.wine(id).get(),
        ]);

        setData({
          beerData: beerDoc.exists ? beerDoc.data() : null,
          cannedBeerData: cannedBeerDoc.exists ? cannedBeerDoc.data() : null,
          specialsData: specialsDoc.exists ? specialsDoc.data() : null,
          wineData: wineDoc.exists ? wineDoc.data() : null,
        });

        if (!beerDoc.exists) {
          setError((prev) => prev + 'Beer not found. ');
        }
        if (!cannedBeerDoc.exists) {
          setError((prev) => prev + 'Canned Beer not found. ');
        }
        if (!specialsDoc.exists) {
          setError((prev) => prev + 'Specials not found. ');
        }
        if (!wineDoc.exists) {
          setError((prev) => prev + 'Wine not found. ');
        }
      } catch (err) {
        setError('Error fetching data');
      }
    };

    fetchData();
  }, [id, props.firebase]);

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!data.beerData || !data.cannedBeerData || !data.specialsData || !data.wineData) {
    return <div className="loading">Loading...</div>;
  }

  const { beerData, cannedBeerData, specialsData, wineData } = data;

  // Group beers by category
  const groupedBeers = beerData.beerList.reduce((acc, beer) => {
    const category = beer.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(beer);
    return acc;
  }, {});

  // Group canned beers by category
  const groupedCannedBeers = cannedBeerData.cannedBeerList.reduce((acc, beer) => {
    const category = beer.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(beer);
    return acc;
  }, {});

  // Sort beers within each category by priority rank
  Object.keys(groupedBeers).forEach((category) => {
    groupedBeers[category].sort(
      (a, b) => (a.priority || Infinity) - (b.priority || Infinity),
    );
  });

  // Sort canned beers within each category by priority rank
  Object.keys(groupedCannedBeers).forEach((category) => {
    groupedCannedBeers[category].sort(
      (a, b) => (a.priority || Infinity) - (b.priority || Infinity),
    );
  });

  // Move the "Uncategorized" category to the top
  const sortedCategories = Object.keys(groupedBeers).sort((a, b) =>
    a === 'Uncategorized' ? -1 : b === 'Uncategorized' ? 1 : 0,
  );

  // Move the "Uncategorized" category to the top (Canned beers)
  const sortedCategoriesCannedBeer = Object.keys(groupedCannedBeers).sort(
    (a, b) => (a === 'Uncategorized' ? -1 : b === 'Uncategorized' ? 1 : 0),
  );

  return (
    <div className="beer-container">
      {beerData?.show ? (
        <div className="beer-menu">
          <h1 className="location-title">BEER LIST</h1>
          <h3 className="location-subtitle">{beerData?.locationName}</h3>
          {sortedCategories.map((category) => (
            <div key={category} className="category-section">
              <ul className="beer-list">
                {groupedBeers[category].map((beer, i) => (
                  <li key={i} className="beer-item">
                    <div className="beer-info">
                      <h3 className="beer-name">{beer.name}</h3>
                      <p className="beer-abv">{beer.abv}%</p>
                    </div>
                    <p className="beer-price">${beer.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
      {cannedBeerData?.show ? (
        <div className="beer-menu">
          <h1 className="location-title">CANNED BEER LIST</h1>
          <h3 className="location-subtitle">{cannedBeerData?.locationName}</h3>
          {sortedCategoriesCannedBeer.map((category) => (
            <div key={category} className="category-section">
              <ul className="beer-list">
                {groupedCannedBeers[category].map((beer, i) => (
                  <li key={i} className="beer-item">
                    <div className="beer-info">
                      <h3 className="beer-name">{beer.name}</h3>
                      <p className="beer-abv">{beer.abv}%</p>
                    </div>
                    <p className="beer-price">${beer.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : null}
      {wineData?.show ? (
        <div className="specials-menu">
          {wineData?.wineList?.length > 0 ? (
            <div className="specials-container">
              <h1 className="specials-title">WINE LIST</h1>
              <h3 className="location-subtitle">{wineData?.locationName}</h3>
              <ul className="specials-list">
                {wineData?.wineList?.map((wine, i) => (
                  <li key={i} className="special-item">
                    <div className="special-info">
                      <h3 className="special-name">{wine.name}</h3>
                      <p className="special-description">{wine.description}</p>
                    </div>
                    <p className="special-price">${wine.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      ) : null}
      {specialsData?.show ? (
        <div className="specials-menu">
          {specialsData?.specialsList?.length > 0 ? (
            <div className="specials-container">
              <h1 className="specials-title">NFL SPECIALS</h1>
              <h3 className="location-subtitle">{specialsData?.locationName}</h3>
              <ul className="specials-list">
                {specialsData?.specialsList?.map((special, i) => (
                  <li key={i} className="special-item">
                    <div className="special-info">
                      <h3 className="special-name">{special.name}</h3>
                      <p className="special-description">{special.description}</p>
                    </div>
                    <p className="special-price">${special.price}</p>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default withFirebase(BeerPage);
