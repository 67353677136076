import React, { useState } from 'react';
import TheBunkerHomePageVideo from '../../assets/thebunker_home_video.mp4';
import './index.scss';
import { Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import locations from '../Location/config';
import { Footer } from '../Landing';
import MenuModal from '../../pages/Location/MenuModal';

// import TB_CP_MENU from '../../assets/menus/Bunker_Menu_NG_CP_20231004.pdf';
// import TB_NG_MENU from '../../assets/menus/Bunker_Menu_NG_CP_20231004.pdf';
// import TB_MH_MENU from '../../assets/menus/Bunker_MH_TheGreen_Menu_20230911.pdf';
// import TB_MH_DESSERT_MENU from '../../assets/menus/Bunker_MH_TheGreen_Dessert_Menu_20230717.pdf';
// import TB_NH_MENU from '../../assets/menus/Bunker_Menu_NH_20231004.pdf';
// import TB_G_MENU from '../../assets/menus/Bunker_G_Menu_20230911.pdf';
import dessert_menu from '../../assets/menus/dessert_menu.pdf';

import { useHistory, Link } from 'react-router-dom';
import MealeoButton from '../../components/MealeoButton';

const MenusPage = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showMenuModal, setMenuModal] = useState(null);

  const history = useHistory();

  const Title = () => (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">MENUS</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/Food%20Wings%20Close%20Up.jpg"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const SectionOne = () => (
    <div className="section">
      <p>
        Each location offers a variety of unique food and drink
        options to enjoy during your visit. See below for our menu
        offerings and stop in for beer and drink specials.
      </p>
      <p>
        Planning a party or group gathering at The Bunker? Submit an
        inquiry on our{' '}
        <a
          className="text-primary font-weight-bold"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.push({ pathname: '/events' });
          }}
        >
          Events
        </a>{' '}
        page!
      </p>
    </div>
  );

  const Main = () => (
    <div className="main flex-fill h-100">
      <SectionOne />
      <Menus />
    </div>
  );

  const Menus = () => {
    const CLIFTON_PARK = locations[0];
    // const LATHAM = locations[2];
    const NORTH_GREENBUSH = locations[2];
    const NEW_HARTFORD = locations[3];
    const MOHAWK_HARBOR = locations[4];
    const GUILDERLAND = locations[1];
    const SARATOGA = locations[5];

    const m = [
      CLIFTON_PARK,
      NORTH_GREENBUSH,
      NEW_HARTFORD,
      MOHAWK_HARBOR,
      GUILDERLAND,
      SARATOGA,
    ];

    return (
      <div className="section">
        <h3 className="font-weight-bold">Menus</h3>
        <hr />

        {m.map((location) => {
          return (
            <div className="menu-list-container">
              <div className="d-flex align-items-center">
                <h4
                  key={location.name}
                  onClick={() => {
                    setMenuModal(location);
                  }}
                  className="text-primary m-0 p-0 p-2 py-3 px-2 link"
                  style={{ cursor: 'pointer'}}
                >
                  {location.name} Menu
                </h4>

                {/* <div className="d-flex text-tertiary p-2 py-3 px-2 ml-3 text-center align-items-center mr-2 my-0">
							NEW ITEMS!
						</div> */}

                {location?.mealeo ? (
                  <MealeoButton
                    title="ORDER TAKEOUT/DELIVERY"
                    url="https://order.getinthebunker.menu/r/67282"
                    small
                    style={{ marginRight: '0.5em' }}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="menu-container">
      <Title />
      <Main />
      <MenuModal
        showMenuModal={showMenuModal}
        close={() => {
          setMenuModal(null);
        }}
      />

      <Footer />
    </div>
  );
};

export default MenusPage;
