import React, { useState, useContext, useEffect } from 'react';
import { compose } from 'recompose';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import { useAlert } from 'react-alert';
import useNoticeInfo from '../../hooks/useNoticeInfo';
import { FirebaseContext } from '../../components/Firebase';
import './index.scss';

const AdminPage = (props) => {
  const firebase = useContext(FirebaseContext);
  const alert = useAlert();

  const { noticeInfo } = useNoticeInfo(firebase);

  const [infoModalData, setInfoModalData] = useState({
    title: noticeInfo.infoModal?.title || '',
    msg: noticeInfo.infoModal?.msg || '',
    alertMsg: noticeInfo.infoModal?.alertMsg || '',
    showInfoModal: noticeInfo.infoModal?.showInfoModal || false,
  });
  const [noticeMsgData, setNoticeMsgData] = useState({
    title: noticeInfo.noticeMsg?.title || '',
    message: noticeInfo.noticeMsg?.message || '',
    showNoticeMsg: noticeInfo.noticeMsg?.showNoticeMsg || false,
  });

  useEffect(() => {
    setInfoModalData({
      title: noticeInfo.infoModal?.title || '',
      msg: noticeInfo.infoModal?.msg || '',
      alertMsg: noticeInfo.infoModal?.alertMsg || '',
      showInfoModal: noticeInfo.infoModal?.showInfoModal || false,
    });
    setNoticeMsgData({
      title: noticeInfo.noticeMsg?.title || '',
      message: noticeInfo.noticeMsg?.message || '',
      showNoticeMsg: noticeInfo.noticeMsg?.showNoticeMsg || false,
    });
  }, [noticeInfo]);

  const handleChange = (event, setData) => {
    const { name, value } = event.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (event, setData) => {
    const { name, checked } = event.target;
    setData((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleSubmit = async (event, data, doc) => {
    event.preventDefault();
    try {
      await props.firebase.db
        .collection('noticeInfo')
        .doc('config')
        .update({
          [doc]: data,
        });
      alert.success(`Successfully updated ${doc} data.`);
    } catch (error) {
      alert.error(`Error updating ${doc} data: ${error.message}`);
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-header">
        <h1 className="m-0">Admin Panel</h1>
      </div>
      <hr />

      <div className="row form-container">
        <div className="col-md-6">
          <div className="card-container">
            <h3>Info Modal</h3>
            <form
              onSubmit={(event) =>
                handleSubmit(event, infoModalData, 'infoModal')
              }
            >
              <div className="form-group">
                <label htmlFor="infoModalAlertMsg">
                  Alert Message
                </label>
                <input
                  className="form-control"
                  id="infoModalAlertMsg"
                  type="text"
                  name="alertMsg"
                  value={infoModalData.alertMsg}
                  onChange={(event) =>
                    handleChange(event, setInfoModalData)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="infoModalTitle">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="infoModalTitle"
                  name="title"
                  value={infoModalData.title}
                  onChange={(event) =>
                    handleChange(event, setInfoModalData)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="infoModalMsg">Message</label>
                <textarea
                  className="form-control"
                  id="infoModalMsg"
                  name="msg"
                  value={infoModalData.msg}
                  onChange={(event) =>
                    handleChange(event, setInfoModalData)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="showInfoModal">Show Info Modal</label>
                <div className="toggle-switch-container">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="showInfoModal"
                      name="showInfoModal"
                      checked={infoModalData.showInfoModal}
                      onChange={(event) =>
                        handleCheckboxChange(event, setInfoModalData)
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="showInfoModal"
                    ></label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Update Info Modal
              </button>
            </form>
          </div>
        </div>
        {/* <div className="p-4" style={{ height: '50px' }}></div> */}

        <div className="col-md-6 pt-4">
          <div className="card-container">
            <h3>Notice Message</h3>
            <form
              onSubmit={(event) =>
                handleSubmit(event, noticeMsgData, 'noticeMsg')
              }
            >
              <div className="form-group">
                <label htmlFor="noticeMsgTitle">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="noticeMsgTitle"
                  name="title"
                  value={noticeMsgData.title}
                  onChange={(event) =>
                    handleChange(event, setNoticeMsgData)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="noticeMsgMessage">Message</label>
                <textarea
                  className="form-control"
                  id="noticeMsgMessage"
                  name="message"
                  value={noticeMsgData.message}
                  onChange={(event) =>
                    handleChange(event, setNoticeMsgData)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="showNoticeMsg">
                  Show Notice Message
                </label>
                <div className="custom-control custom-switch ">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showNoticeMsg"
                    name="showNoticeMsg"
                    checked={noticeMsgData.showNoticeMsg}
                    onChange={(event) =>
                      handleCheckboxChange(event, setNoticeMsgData)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="showNoticeMsg"
                  ></label>
                </div>
              </div>

              <button type="submit" className="btn btn-primary">
                Update Notice Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition))(AdminPage);
