import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import firebase from 'firebase/app';
import 'firebase/storage';
import { useAlert } from 'react-alert';

const SignTVPage = (props) => {
  const [tickerMessage, setTickerMessage] = useState(
    'Welcome to The Bunker!',
  );
  const [progress, setProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);

  const locations = [
    { name: 'Mohawk Harbor', id: 'mohawkharbor' },
    { name: 'Clifton Park', id: 'cliftonpark' },
    // { name: 'Test', id: 'mohawkharbortest' },
  ];

  const ad_page_config = props.firebase.ad_page_config();

  const alert = useAlert();

  useEffect(() => {
    if (location) {
      refreshFileList();
    }
  }, [location]);

  // function refreshFileList() {
  //   const ref = firebase.storage().ref().child(`images/${location}`);
  //   let files = [];

  //   ref
  //     .listAll()
  //     .then((res) => {
  //       res.items.forEach((itemRef) => {
  //         console.log(itemRef.name);
  //         files.push(itemRef.name);
  //       });
  //       setFiles(files);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function refreshFileList() {
    const ref = firebase.storage().ref().child(`images/${location}`);
    let files = [];

    ref
      .listAll()
      .then((res) => {
        res.items.forEach((itemRef) => {
          console.log(itemRef.name);
          files.push(itemRef.name);
        });
        setFiles(files);

        // Update Firestore
        props.firebase.db
          .collection('locations')
          .doc(location)
          .set(
            {
              last_updated:
                firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true },
          )
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveTickerMessage() {
    ad_page_config
      .set({ tickerMessage: tickerMessage })
      .then(() => {
        console.log(
          'successfully updated ticker message to firestore',
        );
        alert.success('Ticker message successfully updated!');
      })
      .catch((err) => {
        alert.error('Ticker message was not updated.');
        console.log(err);
      });
  }

  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFiles(file);
  };

  // function handleDeleteFile(file) {
  //   const storageRef = firebase
  //     .storage()
  //     .ref()
  //     .child(`images/${location}/${file}`);
  //   storageRef
  //     .delete()
  //     .then(() => {
  //       console.log(`${file} deleted successfully`);
  //       alert.success('Image successfully deleted!');
  //       refreshFileList();
  //     })
  //     .catch((error) => {
  //       console.log(`${file} was not deleted ${error}`);
  //       alert.error('Image was not deleted.');
  //     });
  // }

  function handleDeleteFile(file) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file}`);
    storageRef
      .delete()
      .then(() => {
        console.log(`${file} deleted successfully`);
        alert.success('Image successfully deleted!');
        refreshFileList();

        // Update Firestore
        props.firebase.db
          .collection('locations')
          .doc(location)
          .set(
            {
              last_updated:
                firebase.firestore.FieldValue.serverTimestamp(),
            },
            { merge: true },
          )
          .catch((error) => {
            console.log(`${file} was not deleted ${error}`);
            alert.error('Image was not deleted.');
          });
      })
      .catch((error) => {
        console.log(`${file} was not deleted ${error}`);
        alert.error('Image was not deleted.');
      });
  }

  const displayFiles = () => {
    return files.length ? (
      <ol className="d-flex flex-column bg-dark text-white rounded w-100">
        {files.map((file) => {
          return (
            <li className="w-100 p-2">
              <div className="d-flex justify-content-between align-items-center rounded bg-light text-dark p-1">
                <p className="mb-0">{file}</p>
                <button
                  onClick={() => {
                    handleDeleteFile(file);
                  }}
                  className="rounded bg-danger text-white mx-3 px-4 border"
                >
                  Delete
                </button>
              </div>
            </li>
          );
        })}
      </ol>
    ) : (
      <div className="text-danger font-weight-bold">
        No images have been uploaded!
      </div>
    );
  };

  const uploadFiles = (file) => {
    if (!file) return;
    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef
      .child(`images/${location}/${file.name}`)
      .put(file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.round(progress));
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then((downloadURL) => {
            refreshFileList();
          });
      },
    );
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  return (
    <div className="p-3 w-100 h-100">
      <div className="d-flex w-100 justify-content-between">
        <h1 className="m-0">Sign TV Configuration</h1>
        <button
          className="rounded bg-success text-white mx-3 px-4 border"
          disabled={loading}
          onClick={saveTickerMessage}
        >
          Save
        </button>
      </div>
      <hr />
      <div className="w-100">
        <h4>Location:</h4>
        <select value={location} onChange={handleLocationChange}>
          <option value="">Select a location</option>
          {locations.map((loc) => (
            <option value={loc.id}>{loc.name}</option>
          ))}
        </select>
      </div>
      <hr />
      <div className="w-100">
        <h4>Ticker Message:</h4>
        <textarea
          type="textarea"
          className="w-100 p-2"
          value={tickerMessage}
          onChange={(e) => {
            e.preventDefault();
            setTickerMessage(e.target.value);
          }}
        />
      </div>
      <hr />
      {location ? (
        <div className="d-flex w-100">
          <form
            className="d-flex flex-column w-100"
            onSubmit={formHandler}
          >
            <div className="d-flex justify-content-between align-items-center w-100 mb-2">
              <h4 className="m-0">Slide Show Graphics:</h4>
              {progress === 0 ? null : (
                <>
                  {!uploadComplete ? (
                    <strong className="text-warning bold">
                      Upload in progress: {progress}%
                    </strong>
                  ) : (
                    <strong className="text-success bold">
                      Upload successful!
                    </strong>
                  )}
                </>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <input type="file" className="input" />
              <div>
                <button
                  type="submit"
                  className="rounded bg-success text-white mx-1 p-2 border"
                >
                  Upload
                </button>
                <button
                  className="rounded bg-warning mx-1 p-2 border"
                  onClick={() => {
                    setFiles([]);
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <h1 className="font-weight-bold">
          Please select a location above
        </h1>
      )}
      <div className="mt-4">
        <strong>Uploaded Files:</strong>
        {displayFiles()}
      </div>
    </div>
  );
};

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withFirebase,
)(SignTVPage);
