import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import * as ROLES from '../../constants/roles';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import { withFirebase } from '../../components/Firebase';
import { useLocation } from 'react-router-dom';
import { FaStar, FaCheckCircle } from 'react-icons/fa';


const GOOGLE_REVIEW_LINK = 'https://g.page/r/CVzIpRlz_7l7EBI/review';

const StarRating = ({ locationName, username, date }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedback, setFeedback] = useState(''); // State for storing user feedback
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false); // New state for submission status

  // Define a function to render the thank you message
  const renderThankYouMessage = () => (
    <div className="thank-you-message" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#D4EDDA', padding: '15px', borderRadius: '5px' }}>
      <FaCheckCircle style={{ color: '#28A745', marginRight: '10px', fontSize: '24px' }} />
      <p style={{ margin: 0, color: '#155724' }}>Thank you for submitting your feedback. Management will follow up as soon as possible.</p>
    </div>
  );

	  // Define a function to render the personalized message
		const renderPersonalizedMessage = () => (
			<div className="personalized-message">
				<p>Hello {username}, thank you for visiting {locationName} on {date}. We value your feedback!</p>
			</div>
		);
	

  const handleRating = (ratingValue) => {
    setRating(ratingValue);
    
    if (ratingValue === 5) {
      window.open(GOOGLE_REVIEW_LINK, '_blank');
    } else {
      setShowFeedbackForm(true);
    }
  };


  const handleFeedbackSubmit = () => {
    console.log('User feedback:', feedback, 'Rating:', rating);
    // Submit feedback and rating logic here
    setShowFeedbackForm(false);
    setFeedbackSubmitted(true); // Update the state to indicate submission
  };

	const renderFeedbackForm = () => (<div>
		{renderPersonalizedMessage()}

		<div className="feedback-form">

			<textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder="Your feedback here..." />
			<p className="pt-4">We strive for excellence and are committed to improving. Please let us know what we could have done better.</p>
			<button onClick={handleFeedbackSubmit} className="submit-button">Submit Feedback</button>
		</div>
		</div>);

// Define a function to render the star rating
const renderStarRating = () => (
	<div style={{display: 'flex', flexDirection: 'column'}}>
		{renderPersonalizedMessage()}

	<div className="star-rating">
		{[...Array(5)].map((star, index) => {
			const ratingValue = index + 1;

			return (
				<label key={index}>
					<input
						type="radio"
						name="rating"
						value={ratingValue}
						onClick={() => handleRating(ratingValue)}
						style={{ display: 'none' }}
					/>
					<FaStar
						className="star"
						color={ratingValue <= (hover || rating) ? '#ffc107' : '#e4e5e9'}
						size={50}
						onMouseEnter={() => setHover(ratingValue)}
						onMouseLeave={() => setHover(rating)}
					/>
				</label>
			);
		})}
	</div>

		<p>{rating > 0 ? `You rated this ${rating} stars.` : "Click on a star to rate."}</p>
		</div>
);
const renderContent = () => {
	if (feedbackSubmitted) {
		return <div className="thank-you-section">{renderThankYouMessage()}</div>;
	} else if (showFeedbackForm) {
		return <div className="feedback-section">{renderFeedbackForm()}</div>;
	} else {
		return <div className="rating-section">{renderStarRating()}</div>;
	}
};

return (
	<div className="rating-container">
		{renderContent()}
	</div>
);
};

const ReviewsPage = () => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams({
      locationId: searchParams.get('locationId'),
      locationName: searchParams.get('locationName'),
      username: searchParams.get('username'),
      date: searchParams.get('date')
    });
  }, [location.search]);

  return (
    <AuthUserContext.Consumer>
      {authUser => (
				<div className="reviews-page">
        <div className="container">
          <StarRating 
            locationName={queryParams.locationName} 
            username={queryParams.username} 
            date={queryParams.date} 
          />
        </div>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
};

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(
  withFirebase,
  withAuthorization
	(condition),
	)(ReviewsPage);