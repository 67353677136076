import React, { useState, useEffect, useRef } from 'react';
import {
  format,
  startOfWeek,
  addDays,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isToday,
  addMonths,
  isFuture,
  endOfDay,
  sub,
  getDay,
  isSameMonth,
  add,
  isPast,
  startOfDay,
  parse,
  isBefore,
} from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const Calendar = ({ location, firebase }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
	const calendarRef = useRef(null);
	const [calendarHeight, setCalendarHeight] = useState(0);
	
	useEffect(() => {
		if (calendarRef.current) {
			setCalendarHeight(calendarRef.current.offsetHeight);
		}
	}, [calendarRef.current]);
	

  useEffect(() => {
    
		if(!location?.id) return
    const docRef = firebase.calendarEvents(location.id);

    const unsubscribe = docRef.onSnapshot(
      (docSnapshot) => {
        if (docSnapshot.exists) {
          const parsedEvents = docSnapshot
            .data()
            .calendarEvents.map((event) => ({
              ...event,
              date: parse(event.date, 'yyyy-MM-dd', new Date()),
            }));

          const filteredEvents = parsedEvents.filter(
            (event) =>
              isSameMonth(event.date, currentDate) ||
              !isPast(endOfDay(event.date)),
          );
          setEvents(
            filteredEvents.sort(
              (a, b) =>
                a.timestamp.toDate().getTime() -
                b.timestamp.toDate().getTime(),
            ),
          );
        } else {
          setError('Data not found');
        }
      },
      (error) => {
        console.error('Error fetching calendar data: ', error);
        setError('Error fetching calendar data');
      },
    );

    return () => {
      unsubscribe();
    };
  }, [location, firebase, currentDate]);

  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    format(addDays(startOfWeek(currentDate), i), 'EEE'),
  );

  const firstDayOfMonth = startOfMonth(currentDate);
  const startDay = sub(firstDayOfMonth, {
    days: getDay(firstDayOfMonth),
  });

  const endOfCurrentMonth = endOfMonth(currentDate);
  const remainingDaysInWeek = 6 - getDay(endOfCurrentMonth);
  const endDay = add(endOfCurrentMonth, {
    days: remainingDaysInWeek,
  });

  const daysInCalendar = eachDayOfInterval({
    start: startDay,
    end: endDay,
  });

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => addMonths(prevDate, 1));
  };

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => addMonths(prevDate, -1));
  };

  return (
    <div className="calendar container" >
      <div className="row p-2" ref={calendarRef} >
        <div className="col-lg-8 px-0"  style={{ width: '600px'}}>
          <div className="calendar-navigation">
            <button onClick={handlePrevMonth}>Prev</button>
            <span className="h4 mb-0">
              {format(currentDate, 'MMMM yyyy')}
            </span>
            <button onClick={handleNextMonth}>Next</button>
          </div>
          <div className="calendar-header">
            {daysOfWeek.map((day) => (
              <div className="calendar-header-day" key={day}>
                {day}
              </div>
            ))}
          </div>
          <div className="calendar-body">
            {daysInCalendar.map((day, index) => (
              <div
                className={`calendar-day pt-3 ${
                  isSameMonth(day, currentDate) ? '' : 'not-current'
                } ${isToday(day) ? 'today' : ''} ${
                  index % 7 === 6 ? 'end-of-week' : ''
                }`}
                key={day}
              >
                <div className="calendar-day-label">
                  {format(day, 'd')}
                </div>
                {events
                  .filter(
                    (event) =>
                      format(event.date, 'yyyy-MM-dd') ===
                      format(day, 'yyyy-MM-dd'),
                  )
                  .map((event, index) => (
                    <div
                      className={`event text-left truncate ${
                        isBefore(
                          startOfDay(event.date),
                          startOfDay(new Date()),
                        )
                          ? 'expired-event'
                          : ''
                      }`}
                      key={index}
                    >
                      {event.title}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-4 event-list px-0 pt-2 px-md-2 pt-md-0"  style={{ height: events.length ? calendarHeight : '100%' }}>
          <div className="event font-weight-bold h4" style={{ width: '260px'}}>
            Upcoming Events
          </div>
          {events.length > 0 ? (
            events
              .filter(
                (event) =>
                  isFuture(event.date) || isToday(event.date),
              )
              .map((event, index) => (
                <div className="event" key={index}>
                  <div className="event-date">
                    {format(event.date, 'EEEE, MMMM do')}
                  </div>
                  <div className="event-title">{event.title}</div>
                  <div className="">{event.description}</div>
                </div>
              ))
          ) : (
            <div className="no-events">
              No Upcoming Events @ {location?.name}
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
