import React from 'react';

import { Button, Carousel, Modal } from 'react-bootstrap';

const PromotionModal = (props) => {
  const handleClose = () => props.close();

  return (
    <Modal
      show={props?.showPromotionModal !== null}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>
          {props?.showPromotionModal?.name} Promotions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.showPromotionModal?.promotions?.map((promo, i) => (
          <div key={i}>
            <strong>{promo.title}</strong>
            <p>{promo.body}</p>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PromotionModal;
