import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import useWindowDimensions from '../../components/windowDimensionHook.js';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import { Carousel } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/storage';
import './index.scss';
import video from '../../assets/Video.mov';
import eventsVideo from '../../assets/thebunker-events-slide.mp4';

const TickerComponent = (props) => {
  const [tickerMessage, setTickerMessage] = useState('');

  useEffect(() => {
    const ref = firebase
      .firestore()
      .collection('configs')
      .doc('ad_page');

    const subscription = ref.onSnapshot((doc) => {
      if (doc.exists) {
        console.log('Document data:', doc.data());

        const tickerMsg = doc.data().tickerMessage;
        setTickerMessage(tickerMsg);
      }
    });

    return () => {
      subscription();
    };
  }, []);

  const SCREEN_LIM = 1000;
  const CHAR_WIDTH = 100;

  const TICKER_LEN_FULL = (tickerMessage.length + 1) * CHAR_WIDTH;
  const SCREENS_SHOWN = Math.ceil(TICKER_LEN_FULL / SCREEN_LIM);

  const tickerDuration = SCREENS_SHOWN * 5;

  return (
    <div className="news-container">
      <ul>
        <li
          style={{
            animation: `scroll ${tickerDuration}s infinite linear`,
          }}
        >
          {tickerMessage}
        </li>
      </ul>
    </div>
  );
};

const VideoContainer = (props) => {
  const { height, width } = useWindowDimensions();

  return (
    <video
      className="bg-dark"
      width={width}
      height={height - 230}
      autoPlay
      loop
      muted
      playsInline
      id={props.key}
    >
      <source src={props.video} type="video/mp4"></source>
    </video>
  );
};

const Graphic = (props) => {
  const { height } = useWindowDimensions();

  return (
    <div style={{ height: height - 230, objectFit: 'cover' }}>
      <img height={'100%'} width={'100%'} src={props.img} />
    </div>
  );
};

const fetchGraphicImages = async () => {
  const result = await firebase
    .storage()
    .ref(`images/cliftonpark/`)
    .listAll();
  const urlPromises = result.items.map((imageRef) =>
    imageRef.getDownloadURL(),
  );
  return Promise.all(urlPromises);
};

const AdPage = () => {
  const { height, width } = useWindowDimensions();
  const [graphics, setGraphics] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const graphics = await fetchGraphicImages();
        setGraphics(graphics);
      } catch (err) {
        console.log(err);
      }
    };
    loadImages();
  }, []);

  return (
    <div
      className="d-flex flex-column justify-content-end m-0 p-0"
      style={{
        width: width,
        height: height,
        backgroundColor: 'black',
      }}
    >
      <Carousel interval={10000}>
        {/* <Carousel.Item key={0} interval={37000}>
          <VideoContainer video={video} key={0} />
	</Carousel.Item> */}

        {graphics.map((img, i) => (
          <Carousel.Item key={i + 1} className="bg-black text-white">
            <Graphic img={img} />
          </Carousel.Item>
        ))}
      </Carousel>
      <TickerComponent style={{ width: width, height: '600px' }} />
    </div>
  );
};

const condition = (authUser) =>
  authUser && authUser?.roles && !!authUser?.roles[ROLES.ADMIN];

export default compose(withAuthorization(condition))(AdPage);
