import React, { useState, useEffect } from 'react';
import { Button, Carousel, Modal } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import './MenuModal.scss';

const MenuModal = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const handleClose = () => {
		setCurrentMenuIndex(0)	
		props.close()
	
	};

  const [currentMenuIndex, setCurrentMenuIndex] = useState(0);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(null);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(amt) {
    let res = pageNumber + amt;
    if (res < 1) res = numPages;
    if (res > numPages) res = 1;
    setPageNumber(res);
  }

  function onRenderSuccess(page) {
    const viewport = page.getViewport({ scale: 1 });
    setPageWidth(viewport.width);
  }

  function calculateScale() {
    const container = document.querySelector('.modal-body');
    if (!container) return;

    const containerWidth = container.clientWidth;
    const scaleFactor = containerWidth / (pageWidth || 1);
    setScale(scaleFactor);
  }

  useEffect(() => {
    setPageNumber(1);
  }, [currentMenuIndex]);

  useEffect(() => {
    calculateScale();
    window.addEventListener('resize', calculateScale);
    return () => {
      window.removeEventListener('resize', calculateScale);
    };
  }, [pageWidth]);

  return (
    <Modal
      show={props?.showMenuModal !== null}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      className="w-100 h-full"
    >
      <Modal.Header className="bg-dark text-white">
        <Modal.Title>{props?.showMenuModal?.name} Menu</Modal.Title>
        <div className="menu-buttons p-1">
          {props?.showMenuModal?.menus?.map((menu, index) => (
            <Button
              size="sm"
              key={index}
              variant={
                index === currentMenuIndex ? 'primary' : 'light'
              }
              onClick={() => setCurrentMenuIndex(index)}
							className="w-100"
            >
              {menu?.name || 'Menu'}
            </Button>
          ))}
        </div>
      </Modal.Header>

      <Modal.Body className="bg-dark text-white d-flex flex-column justify-content-center">
        <div className="pdf-document-container">
          <Document
            style={{ width: '100%', height: '100%' }}
            file={props?.showMenuModal?.menus[currentMenuIndex]?.pdf}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              onRenderSuccess={onRenderSuccess}
              scale={scale}
            />
          </Document>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <Button
            variant="light"
            className=""
            onClick={() => changePage(-1)}
          >
            Prev
          </Button>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <Button variant="light" onClick={() => changePage(1)}>
            Next
          </Button>
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-dark">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MenuModal;
