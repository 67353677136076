import React from 'react';

import { withFirebase } from '../../components/Firebase';

const SignOutButton = ({ firebase }) => (
  <button type="button" className="rounded bg-warning mx-1 p-2 border-0" onClick={firebase.doSignOut}>
    Admin Log Out
  </button>
);

export default withFirebase(SignOutButton);
