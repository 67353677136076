import React, { useState } from 'react';
import { Footer } from '../Landing';
import { withFirebase } from '../../components/Firebase';
import { useAlert } from 'react-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { sendEmail } from '../../utils/email';
import { formatPhone } from '../../utils/format';
import '../../utils/shared.scss';
import './index.scss';
import { Form, Button } from 'react-bootstrap';
import CareerApplicationForm from '../../components/FileUploadComponent'

const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const TEXT_REGEX = /[^a-zA-Z]/g;
const TEXTBOX_REGEX = /[^a-zA-Z0-9.,!$?\n ]/g;
const PHONE_REGEX = /[^\d]/g;

const HEADER = 'CAREERS';
const TITLE = 'Thinking about a career with The Bunker?';
const SUBTITLE =
  "Contact us using the form below. Let's find the right fit!";

const CareersPage = (props) => {
  let [loading, setLoading] = useState(false);


  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/events-bg.png"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );


  const Main = () => {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <div
        className="main px-3 flex-fill h-100"
        // style={{ overflowY: 'scroll' }}
      >
        <div className="pt-4">
          <h1 className="text-dark mb-4">{TITLE}</h1>
          <p className="ml-1">{SUBTITLE}</p>
          <hr />
        </div>
        {loading ? (
          <ClipLoader loading={loading} css={override} size={150} />
        ) : (
          <CareerApplicationForm firebase={props.firebase} />
        )}
      </div>
    );
  };

  return (
    <div className="lessons-container">
      {Title}
      <Main />
      <Footer />
    </div>
  );
};

export default withFirebase(CareersPage);
