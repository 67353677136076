import React, { useState } from 'react';
import TheBunkerHomePageVideo from '../../assets/thebunker_home_video.mp4';
import './index.scss';
import { Button, Form } from 'react-bootstrap';
import { Footer } from '../Landing';
import locations from '../Location/config';
import { GiSmartphone } from 'react-icons/gi';
import { MdEmail } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';
import { set } from 'date-fns';
import { useAlert } from 'react-alert';
import { CONTACT } from '../../constants/routes';
import BookNowButton from '../../components/BookNowButton/BookNowButton.js';
import useAsyncDebounce from '../../hooks/useAsyncDebounce';
import Loading from 'react-loading';
import BookEventsButton from '../../components/BookEventsButton/BookEventsButton';

const LoadingAnimation = () => {
  return (
    <div
      className=""
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#eee',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      sending...
    </div>
  );
};

const ContactUsPage = (props) => {
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const Title = () => (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">CONTACT</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/contact_us_bg.png"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const Main = () => {
    const history = useHistory();

    const [lName, setLName] = useState('');
    const [fName, setFName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);

    function handleSubmit() {
      const payload = {
        name: fName + ' ' + lName,
        email: email,
        phone: phone,
        message: message,
        emailTo: ['info@getinthebunker.golf'],
      };

      debouncedSendEmail(payload);
    }

    function emailValidation() {
      const check = email || '';
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return check.match(regex);
    }

    function phoneValidation() {
      // const check = phone || ""
      // const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;
      // return check.match(regex)
      return phone && phone !== '';
    }

    function validate() {
      let errors = [];

      const NOT_FILLED_OUT =
        lName === '' ||
        fName === '' ||
        (email === '' && phone === '') ||
        message === '';

      const CONTACT_EXISTS = emailValidation() || phoneValidation();

      if (NOT_FILLED_OUT) errors.push('Please fill out all fields.');
      if (!CONTACT_EXISTS)
        errors.push(
          'A valid email address or phone number is required.',
        );

      setErrors(errors);
      return errors.length;
    }

    const debouncedSendEmail = useAsyncDebounce(sendEmail, 3000);

    async function sendEmail(payload) {
      const sendEmail_ = props.firebase.sendEmail();

      if (validate() > 0) {
        return;
      }
      setSubmitting(true);

      try {
        const result = await sendEmail_(payload, { auth: true });
        alert.success('Your inquiry has been sent to The Bunker!');
        setSubmitted(true);

        setLName('');
        setFName('');
        setEmail('');
        setPhone('');
        setMessage('');
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    }

    function sanitizeMessage(message) {
      // return message.replace(/[^a-zA-Z0-9.,!$?@#%^&*()'"_-+=;:\n ]/g, '');
			return message
    }

    function sanitizeName(message) {
      return message.replace(/[^a-zA-Z]/g, '');
    }

    function sanitizePhone(message) {
      // return message.replace(/[^0-9]/g, '') || "";
      return message.replace(/[^\d]/g, '');
    }

    function formatPhoneNumber(phoneNumberString = '') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      } else {
        return phone;
      }
    }

    return (
      <div className="main px-3 flex-fill h-100">
        {/* TITLE */}
        <div className="pt-4 ml-4">
          {/* <h1 className="text-dark mb-4">QUESTIONS?</h1>
          <p>
            Contact us using the form below or feel free to call or
            email us directly using the information provided below.
            <br />
            We look forward to serving you!
          </p> */}
					<p className="h4 font-weight-bold">If you are looking to book a party, please go to <a target="_blank" href="https://www.bunkerparties.com">BunkerParties.com</a></p>
        </div>

        {/* FORM */}

        {/* <div className="bg-light rounded mx-4 p-3 position-relative">
          {submitting && <LoadingAnimation />}

          {submitted ? (
            <p
              className="text-primary font-weight-bold mb-0"
              style={{ fontSize: 36 }}
            >
              Your inquiry has been sent to The Bunker!
            </p>
          ) : (
            <Form>
              <div className="d-flex space-around">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={fName}
                    onChange={(e) =>
                      setFName(sanitizeName(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lName}
                    onChange={(e) =>
                      setLName(sanitizeName(e.target.value))
                    }
                  />
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="phone"
                    value={formatPhoneNumber(phone)}
                    onChange={(e) =>
                      setPhone(sanitizePhone(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </div>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <textarea
                  className="rounded w-100 p-3"
                  style={{ borderWidth: '0px', borderColor: 'white' }}
                  type="textarea"
                  rows="3"
                  value={message}
                  onChange={(e) =>
                    setMessage(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <div className="mt-4">
                {errors.map((err) => {
                  return (
                    <p
                      key={err}
                      className="text-primary font-weight-bold"
                    >
                      {err}
                    </p>
                  );
                })}
              </div>
              <Button
                size="lg"
                className="primary m-2"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Form>
          )}
        </div> */}

        {/* LOCATIONS */}
        <div className="d-flex w-100 flex-wrap">
          {locations.map((loc) => (
            <div
              key={loc.name}
              className="d-flex flex-column m-3 mx-4 border rounded p-2"
              style={{ width: 400, height: 325 }}
            >
              <h3 className="font-weight-bold">
                {loc.name.toUpperCase()}
              </h3>
              <p>{loc.address}</p>
              <div className="text-primary d-flex align-items-center">
                <GiSmartphone size="40" />
                <a
                  className="font-weight-bold mb-0 ml-3"
                  href={'tel:+1 ' + loc.phone}
                >
                  {loc.phone}
                </a>
              </div>
              <div className="text-primary d-flex align-items-center">
                <MdEmail size="40" />
                <a
                  className="font-weight-bold mb-0 ml-3"
                  href={'mailto:' + loc.email}
                >
                  {loc.email}
                </a>
              </div>
              <div>
                {loc.hoursShort ? (
                  <p className="my-2">
                    <span className="font-weight-bold">Hours:</span>{' '}
                    {loc.hoursShort}
                  </p>
                ) : null}
                {loc.leaguesShort ? (
                  <p className="my-2">
                    <span className="font-weight-bold">
                      League times:
                    </span>{' '}
                    {loc.leaguesShort}{' '}
                  </p>
                ) : (
                  <br className="my-2" />
                )}
              </div>
              <div className="w-100 h-100 d-flex justify-content-between align-items-end">
                <BookNowButton url={loc.url} />
								<BookEventsButton />

              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="contact-container">
      <Title />
      <Main />
      <Footer />
    </div>
  );
};

export default withFirebase(ContactUsPage);
