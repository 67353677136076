import React, { useState } from 'react';
import './index.scss';
import { Button, Form } from 'react-bootstrap';
import { Footer } from '../Landing';
import { useHistory } from 'react-router-dom';
import { withFirebase } from '../../components/Firebase';
import { useAlert } from 'react-alert';
import ClipLoader from 'react-spinners/ClipLoader';
import { css } from '@emotion/react';
import EventModal from './EventModal';

import guilderland_events from '../../assets/events/guilderland_events.pdf';
import cliftonpark_events from '../../assets/events/cliftonpark_events.pdf';
import northgreenbush_events from '../../assets/events/northgreenbush_events.pdf';
import newhartford_events from '../../assets/events/newhartford_events.pdf';

const EventsPage = (props) => {
  let [loading, setLoading] = useState(false);

  const alert = useAlert();
  const [showEventsPDF, setShowEventsPDF] = useState(false);
  const [activeEventPDF, setActiveEventPDF] = useState(null);

  const pdfMap = [
    { name: 'Guilderland', pdf: guilderland_events },
    { name: 'Clifton Park', pdf: cliftonpark_events },
    { name: 'North Greenbush', pdf: northgreenbush_events },
    { name: 'New Hartford', pdf: newhartford_events },
  ];

  const Title = () => (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">EVENTS</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/events-bg.png"
        width={'100%'}
        height={'100%'}
      />
    </div>
  );

  const Main = () => {
    const history = useHistory();

    const [lName, setLName] = useState('');
    const [fName, setFName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [guests, setGuests] = useState('');
    const [eventType, setEventType] = useState('');
    const [preferredLocation, setPreferredLocation] = useState('');
    const [guestCount, setGuestCount] = useState('');
    const [guestsGolfing, setGuestsGolfing] = useState('');
    const [menu, setMenu] = useState('');
    const [surpriseParty, setSurpriseParty] = useState('');
    const [decorations, setDecorations] = useState('');
    const [message, setMessage] = useState('');
    const [proposedDates, setProposedDates] = useState('');
    const [preferredTime, setPreferredTime] = useState('');
    const [preferredDuration, setPreferredDuration] = useState('');
    const [errors, setErrors] = useState([]);

    function handleSubmit() {
      const payload = {
        name: fName + ' ' + lName,
        email: email,
        phone: phone,
        message: message,
        guests: guests,
        eventType: eventType,
        template: 'event_template',
        emailTo: ['events@getinthebunker.golf'],
        preferredLocation,
        guestCount,
        guestsGolfing,
        menu,
        surpriseParty,
        decorations,
        proposedDates,
        preferredTime,
        preferredDuration,
      };

      const payload_client = {
        emailTo: [email],
        template: 'event_template_client',
      };

      sendEmail(payload, payload_client);
    }

    function emailValidation() {
      const check = email || '';
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return check.match(regex);
    }

    function phoneValidation() {
      // const check = phone || ""
      // const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/i;
      // return check.match(regex)

      return phone && phone !== '';
    }

    function validate() {
      let errors = [];

      const NOT_FILLED_OUT =
        lName === '' ||
        fName === '' ||
        (email === '' && phone === '') ||
        message === '' ||
        eventType === '' ||
        guests === '';

      console.log(
        JSON.stringify(
          {
            email,
            lName,
            fName,
            email,
            phone,
            guests,
            eventType,
            message,
          },
          undefined,
          2,
        ),
      );
      const CONTACT_EXISTS = emailValidation() || phoneValidation();

      if (NOT_FILLED_OUT) errors.push('Please fill out all fields.');
      if (!CONTACT_EXISTS)
        errors.push(
          'A valid email address or phone number is required.',
        );

      setErrors(errors);
      return errors.length;
    }

    async function sendEmail(payload, payload_client) {
      const sendEmail_ = props.firebase.sendEmail();
      setLoading(true);

      if (validate() > 0) {
        setLoading(false);
        return;
      }

      try {
        const result = await sendEmail_(payload, { auth: true });
        const result_client = await sendEmail_(payload_client);

        alert.success(
          'Your inquiry has been sent to The Bunker! Please check your inbox for a follow-up email!',
          { timeout: 6000, position: 'top center' },
        );

        setLName('');
        setFName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setGuests('');
        setEventType('');
        setPreferredLocation('');
        setGuestCount('');
        setGuestsGolfing('');
        setMenu('');
        setSurpriseParty('');
        setDecorations('');
        setProposedDates('');
        setPreferredTime('');
        setPreferredDuration('');
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    function sanitizeMessage(message) {
      return message.replace(/[^a-zA-Z0-9.,!$?\n ]/g, '');
    }

    function sanitizeName(message) {
      return message.replace(/[^a-zA-Z]/g, '');
    }

    function sanitizePhone(message) {
      return message.replace(/[^\d]/g, '');
    }

    function formatPhoneNumber(phoneNumberString = '') {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      } else {
        return phone;
      }
    }
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
      <div className="main px-3 flex-fill h-100">
        {/* TITLE */}
        <div className="p-0 ml-4">
          <div className="mb-3"></div>
          <h1 className="text-dark mb-4">
            Want to book a party at The Bunker?
          </h1>
          <p>
            Contact us using the form below. We look forward to
            serving you!
          </p>
        </div>

        {/* FORM */}
        <div className="bg-light rounded mx-4 p-3">
          {!loading ? (
            <Form>
              <div className="d-flex space-around">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={fName}
                    onChange={(e) =>
                      setFName(sanitizeName(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={lName}
                    onChange={(e) =>
                      setLName(sanitizeName(e.target.value))
                    }
                  />
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="phone"
                    value={formatPhoneNumber(phone)}
                    onChange={(e) =>
                      setPhone(sanitizePhone(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Event Package</Form.Label>
                  <Form.Control
                    type="text"
                    value={eventType}
                    onChange={(e) =>
                      setEventType(sanitizeMessage(e.target.value))
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Guest Count</Form.Label>
                  <Form.Control
                    type="email"
                    value={guests}
                    onChange={(e) =>
                      setGuests(sanitizePhone(e.target.value))
                    }
                  />
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Preferred Start Time</Form.Label>
                  <Form.Control
                    type="text"
                    value={preferredTime}
                    onChange={(e) =>
                      setPreferredTime(
                        sanitizeMessage(e.target.value),
                      )
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="m-2 flex-fill"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Preferred Booking Duration</Form.Label>
                  <Form.Control
                    type="email"
                    value={preferredDuration}
                    onChange={(e) =>
                      setPreferredDuration(
                        sanitizeMessage(e.target.value),
                      )
                    }
                  />
                </Form.Group>
              </div>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <textarea
                  className="rounded w-100 p-3"
                  style={{ borderWidth: '0px', borderColor: 'white' }}
                  type="textarea"
                  rows="3"
                  value={message}
                  onChange={(e) =>
                    setMessage(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  What is your preferred location?
                </Form.Label>
                <Form.Control
                  type="text"
                  value={preferredLocation}
                  onChange={(e) =>
                    setPreferredLocation(
                      sanitizeMessage(e.target.value),
                    )
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  How many guests will be attending?
                </Form.Label>
                <Form.Control
                  type="text"
                  value={guestCount}
                  onChange={(e) =>
                    setGuestCount(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  How many guests will be golfing?
                </Form.Label>
                <Form.Control
                  type="text"
                  value={guestsGolfing}
                  onChange={(e) =>
                    setGuestsGolfing(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Will you be ordering off our Regular or Catering
                  menu?
                </Form.Label>
                <Form.Control
                  type="text"
                  value={menu}
                  onChange={(e) =>
                    setMenu(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Is the event a surprise party? If yes, please
                  provide details:
                </Form.Label>
                <textarea
                  className="rounded w-100 p-3"
                  style={{ borderWidth: '0px', borderColor: 'white' }}
                  type="textarea"
                  rows="3"
                  value={surpriseParty}
                  onChange={(e) =>
                    setSurpriseParty(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Will you be bringing decorations? (banners and
                  balloons only)
                </Form.Label>
                <Form.Control
                  type="text"
                  value={decorations}
                  onChange={(e) =>
                    setDecorations(sanitizeMessage(e.target.value))
                  }
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>
                  Proposed date or dates of your event:
                </Form.Label>
                <Form.Control
                  type="text"
                  value={proposedDates}
                  onChange={(e) => setProposedDates(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="m-2 flex-fill h-100"
                controlId="exampleForm.ControlTextarea1"
              >
                <p className="font-weight-bold mt-2 mb-0">
                  * 15 guests or more require 15 minutes on front and
                  back end of reservation to allow for setup and
                  cleanup.
                </p>
                <p className="m-0">
                  (Example: a party from 7-9 becomes a reservation
                  from 6:45-9:15)
                </p>
              </Form.Group>
              <div className="mt-4">
                {errors.map((err) => {
                  return (
                    <p
                      key={err}
                      className="text-primary font-weight-bold"
                    >
                      {err}
                    </p>
                  );
                })}
              </div>
              <Button
                size="lg"
                className="primary m-2"
                onClick={handleSubmit}
              >
                SUBMIT
              </Button>
            </Form>
          ) : (
            <ClipLoader loading={loading} css={override} size={150} />
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="events-container">
      <Title />
      <div className="d-flex flex-column">
        {pdfMap.map((location, index) => {
          return (
            <Button
              key={index}
              className="m-1"
              onClick={() => {
                setActiveEventPDF(location);
                setShowEventsPDF(true);
              }}
            >
              {location.name} Events
            </Button>
          );
        })}
      </div>
      <EventModal
        showEventModal={showEventsPDF}
        location={activeEventPDF}
        close={() => {
          setActiveEventPDF(null);
          setShowEventsPDF(false);
        }}
      />
      <Main />
      <Footer />
    </div>
  );
};

export default withFirebase(EventsPage);
